import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
    return (
        <Layout>
            <SEO
                title="Marina Trapp Logopädie"
                keywords={[`Marina`, `Trapp`, `Logopädie`, `Sprachtherapie`, `Therapie`, `Sprachstörung`, `Schlaganfall`, `Speech therapy`]}
            />

            <div className="container max-w-5xl mx-auto m-8 text-gray-500">
                <h1 className="w-full my-2 text-3xl leading-tight text-center">Impressum</h1>
                <p className="w-full my-2 text-sm text-center">
                    Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz
                </p>
                <section className="my-4">
                    <p className="w-full text-2xl text-center">Marina Trapp, BA, BSc</p>
                    <p className="w-full text-lg text-center">Logopädin</p>
                    <p className="w-full text-lg text-center">+43 (0)650 517 4923</p>
                    <p className="w-full text-lg text-center">anfrage@logopaedie-trapp.at</p>
                    <p className="w-full text-lg text-center">
                        <a href="https://www.logopaedie-trapp.at">www.logopaedie-trapp.at</a>
                    </p>
                </section>
                <section className="my-4">
                    <p>Berufsrecht: https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10010701</p>

                    <p>Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Innsbruck</p>
                    <p>Berufsbezeichnung: Logopädin</p>
                    <p>Verleihungsstaat: Österreich</p>
                </section>
                <section className="my-4">
                    <h2 className="w-full my-2 text-xl leading-tight">EU-Streitschlichtung</h2>
                    <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.</p>
                    <p>Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=121557738 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
                    <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                </section>

                <section className="my-4">
                    <h2 className="w-full my-2 text-xl leading-tight">Haftung für Inhalte dieser Webseite</h2>
                    <p>Der Betreiber entwickelte die Inhalte dieser Webseite ständig weiter und ist bemüht korrekte und aktuelle Informationen bereitzustellen.
                        Der Betreiter übernimmt keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite.</p>
                </section>

                <section className="my-4">
                    <h2 className="w-full my-2 text-xl leading-tight">Urheberrechtshinweis</h2>
                    <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht.
                        Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                        Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
                </section>


                <section className="my-4">
                    <h2 className="w-full my-2 text-xl leading-tight">Bildernachweis</h2>
                    <p>
                        Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
                        Die Bilderrechte liegen bei Marina Trapp BA, BSc.
                    </p>
                </section>

            </div>
        </Layout>
    );
}

export default IndexPage;
